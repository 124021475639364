import React from 'react';
import { Routes } from './routes';
import './App.css';
//For internet expolorer 
import 'react-app-polyfill/stable';
import "isomorphic-fetch"

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
