import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './fonts/fonts.css';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N3ZXHSN',
};
// const tagManagerArgs = {
//     gtmId: 'GTM-KCC8MNX',
//   };
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <BrowserRouter forceRefresh={true}>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
