import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

const Lp = React.lazy(() => import("./pages/lp/lp"));
const Post = React.lazy(() => import("./posts/Post"));

export const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<div>...</div>}>
        <Switch>
          <Route path="/post/:id" component={Post} />
          <Route
            path="/ar"
            render={() => (window.location = "https://ar.i-israel.co.il")}
            exact
          />
          <Route path="/" component={Lp} exact />
        </Switch>
      </Suspense>
    </Router>
  );
};
